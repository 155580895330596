<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_size + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Увеличитель куба +20 л</div>
          <div class="im-subtitle">Для перегонных ёмкостей диаметром 36 см</div>
          <div class="im-text">
            <p>
              Наиболее популярен в самогоноварении объём аппарата 37 л. И
              новичкам для начала экспериментов такого объёма вполне достаточно.
              Но, по мере роста профессионализма, винокуры практически всегда
              хотят увеличить перегонный куб.
            </p>
            <p>
              Увеличитель куба DomSpirt 20 л позволяет не сомневаться, какой
              объём выбрать: 37 л бак можно увеличить хоть до 200 л, докупив
              модули с аппаратом либо позже в любой момент. Модульный куб -
              лучший выбор для перегонки, т.к. брага (большой объём, например,
              50 л) перегоняется в большом кубе, а полученный сырец (с 50 л
              браги получится 15 л сырца) - в компактном базовом (модуль
              убирается). В компактном кубе меньше теплопотерь, быстрее
              разогрев, меньше неснижаемый остаток! 360 мм позволяет
              использовать увеличитель для любых ёмкостей перегонных аппаратов
              такого же размера. Материал - пищевая нержавеющая сталь AISI 304.
              Обруч и силиконовая прокладка с армирующим кольцом в комплекте.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_size = require("@/pages/Main/assets/modal/m_size.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_size,
      car,
    };
  },
};
</script>

<style scoped></style>
